import {useEffect} from 'react';
import Header from './Shared/Header';

function ContactUs() {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <>
        <Header name={"Contact Us"}/>
        {/* <!-- ================ contact section start ================= --> */}
            <section class="contact-section">
                    <div class="container">
                        <div class="row ">
                            <div class="col-xl-12">
                                <div class="section-tittle text-center mb-70">
                                    <h2>Offices</h2>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-5">
                            <div class="col-12">
                                <h1 class="contact-title">Karachi Office</h1>
                            </div>
                            <div class="col-lg-8">
                                <div class="d-sm-block ">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2416.2831852288473!2d67.03413966529074!3d24.852434709520363!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33f4d96879c2b%3A0xa722f315a4947419!2sTANWEER%20%26%20ASSOCIATES!5e0!3m2!1sen!2s!4v1639412668620!5m2!1sen!2s" width="100%" height="350" style={{border:"0"}} allowfullscreen="" loading="lazy"/>
                                </div>
                            </div>
                            <div class="col-lg-4 pt-55">
                                <div class="media contact-info">
                                    <span class="contact-info__icon"><i class="ti-home"></i></span>
                                    <div class="media-body">
                                        <h3>Shahra-e-Faisal, Karachi.</h3>
                                        <p>307, Kashif Centre, Near Mehran Hotel.</p>
                                    </div>
                                </div>
                                <div class="media contact-info">
                                    <span class="contact-info__icon"><i class="ti-tablet"></i></span>
                                    <div class="media-body">
                                        <h3>+921-35640302-04</h3>
                                        <p>Mon to Sat 10am to 6pm</p>
                                    </div>
                                </div>
                                <div class="media contact-info">
                                    <span class="contact-info__icon"><i class="ti-email"></i></span>
                                    <div class="media-body">
                                        <h3>office@tanweerassociates.com</h3>
                                        <p>Send us your query anytime!</p>
                                    </div>
                                </div>
                            </div>
                        
                        </div>
                        <div class="row mb-5">
                            <div class="col-12">
                                <h1 class="contact-title">Quetta Office</h1>
                            </div>
                            <div class="col-lg-8">
                                <div class="d-sm-block ">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2416.2831852288473!2d67.03413966529074!3d24.852434709520363!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33f4d96879c2b%3A0xa722f315a4947419!2sTANWEER%20%26%20ASSOCIATES!5e0!3m2!1sen!2s!4v1639412668620!5m2!1sen!2s" width="100%" height="350" style={{border:"0"}} allowfullscreen="" loading="lazy"/>
                                </div>
                            </div>
                            <div class="col-lg-4 pt-55">
                                <div class="media contact-info">
                                    <span class="contact-info__icon"><i class="ti-home"></i></span>
                                    <div class="media-body">
                                        <h3>Shahra-e-Liaqat, Quetta</h3>
                                        <p>26, Baldia Plaza, 3rd Floor</p>
                                    </div>
                                </div>
                                <div class="media contact-info">
                                    <span class="contact-info__icon"><i class="ti-tablet"></i></span>
                                    <div class="media-body">
                                        <h3>+921-35640302-04</h3>
                                        <p>Mon to Sat 10am to 6pm</p>
                                    </div>
                                </div>
                                <div class="media contact-info">
                                    <span class="contact-info__icon"><i class="ti-email"></i></span>
                                    <div class="media-body">
                                        <h3>office@tanweerassociates.com</h3>
                                        <p>Send us your query anytime!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-5">
                            <div class="col-12">
                                <h1 class="contact-title">Gwadar Office</h1>
                            </div>
                            <div class="col-lg-8">
                                <div class="d-sm-block ">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2416.2831852288473!2d67.03413966529074!3d24.852434709520363!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33f4d96879c2b%3A0xa722f315a4947419!2sTANWEER%20%26%20ASSOCIATES!5e0!3m2!1sen!2s!4v1639412668620!5m2!1sen!2s" width="100%" height="350" style={{border:"0"}} allowfullscreen="" loading="lazy"/>
                                </div>
                            </div>
                            <div class="col-lg-4 pt-55">
                                <div class="media contact-info">
                                    <span class="contact-info__icon"><i class="ti-home"></i></span>
                                    <div class="media-body">
                                        <h3>Airport Road, Gwadar.</h3>
                                        <p>Opposite PIA Office.</p>
                                    </div>
                                </div>
                                <div class="media contact-info">
                                    <span class="contact-info__icon"><i class="ti-tablet"></i></span>
                                    <div class="media-body">
                                        <h3>+921-35640302-04</h3>
                                        <p>Mon to Sat 10am to 6pm</p>
                                    </div>
                                </div>
                                <div class="media contact-info">
                                    <span class="contact-info__icon"><i class="ti-email"></i></span>
                                    <div class="media-body">
                                        <h3>office@tanweerassociates.com</h3>
                                        <p>Send us your query anytime!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            {/* <!-- ================ contact section end ================= --> */}
        </>
    );
  }
  
  export default ContactUs;