import {useEffect} from 'react';
import Header from './Shared/Header';

function OurExpertise() {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <>
        <Header name={"Our Expertise"} />
            {/* <!-- Legal Practice Area start --> */}
                <div class="legal-practice-area section-padding30">
                    <div class="container">
                        {/* <!--Section Tittle  --> */}
                        <div class="row" >
                            <div class="col-xl-12">
                                <div class="section-tittle text-center mb-70">
                                    <h2>Areas Of Expertise.</h2>
                                </div>
                            </div>
                        </div>
                        {/* <!-- single items --> */}
                        <div class="row" style={{justifyContent:"center"}}>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Admiralty & Shipping</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Arbitration & Dispute Resolution</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Banking Laws</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Company Law</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Constitutional Law</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Co-operative Housing Society Laws</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Corporate Dealings & Advisory</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Contractual Disputes</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Drafting & Vetting Agreements</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Drug Laws </a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Environmental Laws</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Inheritance and Succession Law</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Intellectual Property</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Insurance Laws</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >General Civil Litigation</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Labour Laws & Civil Service Matters</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Local Government Laws</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Partnership Laws</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Petroleum Laws</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Property & Land Revenue matters</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Public Interest Litigation</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Registration matters</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Taxation Matters including Income Tax, Sales Tax & Customs Duty</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >S.E.C.P Matters</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Transactional Advisory</a></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* <!-- Legal Practice Area End--> */}
        </>
    );
  }
  
  export default OurExpertise;