import {useEffect} from 'react';
import { BrowserRouter as Router, Routes , Route } from 'react-router-dom'
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer';
import Home from './Components/Home/Home';
import ContactUs from './Components/ContactUs';
import AboutUs from './Components/AboutUs';
import OurClients from './Components/ourClients/OurClients';
import OurExpertise from './Components/OurExpertise';
import OurTeam from './Components/OurTeam';

function App() {


  return (
    <>
      <Router>
        <Navbar />
        {/* <Home/> */}
        <Routes>
          <Route exact={true} path="/" element={<Home/>} />
          <Route exact={true} path="/about" element={<AboutUs/>} />
          <Route exact={true} path="/contact" element={<ContactUs/>} />
          {/* <Route exact={true} path="/our_team" element={<OurTeam/>} /> */}
          <Route exact={true} path="/our_clients" element={<OurClients/>} />
          <Route exact={true} path="/our_expertise" element={<OurExpertise/>} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
