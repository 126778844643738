import {useEffect} from 'react';
import Header from './Shared/Header';
import ClientsComments from './Home/ClientsComment';
import OurTeam from './OurTeam';

function AboutUs() {
    
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      
    return (
        <>
            <Header name={"About Us"}/>
         
            {/* <ClientsComments/> */}
            <OurTeam/>
        </>
    );
  }
  
  export default AboutUs;