
import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import "./style.css";

function Navbar() {
    const screenWidth = window.innerWidth

    const imgCondition =() =>{
      // if(screenWidth < 270)
      //   return <img src="assets/logo/logo-orignal100X.png" alt=""/>
      // else if(screenWidth < 300)
      //   return <img src="assets/logo/logo-orignal150X.png" alt=""/>
      // else if(screenWidth < 350)
      //   return <img src="assets/logo/logo-orignal200X.png" alt=""/>
      // else if(screenWidth < 375)
      //   return <img src="assets/logo/logo-orignal250X.png" alt=""/>
      // else if(screenWidth < 400)
      //     return <img src="assets/logo/logo-orignal300X.png" alt=""/>
      // else if(screenWidth < 900)
      //     return  <img src="assets/logo/logo-horizontal140X16.png" alt=""/>
      // else if(screenWidth < 1200)
      //   return  <img src="assets/logo/logo-horizontal240X27.png" alt=""/>
      // else if(screenWidth < 1400)
      //     return  <img src="assets/logo/logo-horizontal440X49.png" alt=""/>
        // return <img src="assets/logo/logo-horizontal500X56.png" alt=""/>
        if(screenWidth < 400)
           return <img src="assets/logo/logo-orignal250X.png" alt=""/>
        else if(screenWidth < 900)
            return  <img src="assets/logo/logo-orignal300X.png" alt=""/>
        return <img src="assets/logo/orignal_100x343.png" alt=""/>
    }

    // window.onscroll = function() {
    //   var eleme = document.getElementById("header");
    //   console.log("onscroll")
    //   console.log(eleme.scrollHeight)
      
    //   if (elements.scrollHeight > 900){
    //     console.log("onscroll11")
        
    //   }
    //   var elements = document.getElementsByClassName("header_area");
    //   for (let i = 0; i < elements.length; i++) {
    //       elements[i].classList.add('navbar_fixed');
    //   }
    // }
    

  return (
    <>
      <header className="header_area " id="header">
          {/* <nav class="top_nav" > */}

          <nav class="top_nav" >
            <div class="container top_nav_section">
              <div class="top_nav_section_left">
                  <span>CALL US: +921-35640302-04</span>
              </div>
              <div class="top_nav_section_right">
                  <span>EMAIL: office@tanweerassociates.com</span>
              </div>
            </div>
          </nav>
          {/* <nav class="navbar navbar-expand-lg navbar-light center_nav">
            <div class="container justify-content-center">
              <Link to="/" exact={true} >  {imgCondition()} </Link> 
            </div>
          </nav> */}


            {/* <div class="container top_nav_section"> */}
            {/* <div class="container">
                <div class="row ">
                     <div class="col-xl-6 col-lg-6 col-md-6">
                     </div>
                     <div class="col-xl-6 col-lg-6 col-md-6">
                        <div class="top_nav_section_right_container">
                          <div class="top_nav_section_left">
                                <span>CALL US: +921-35640302-04</span>
                          </div>
                          <div class="top_nav_section_right">
                              <span>EMAIL: office@tanweerassociates.com</span>
                          </div>                      
                        </div>
                     </div>
                    </div>
                  </div> */}

              {/* <div class="top_nav_section_left"> */}
                  {/* <span>CALL US: +921-35640302-04</span> */}
              {/* </div> */}
              {/* <div class="top_nav_section_right_container">
                <div class="top_nav_section_left">
                    <span>CALL US: +921-35640302-04</span>
                </div>
                <div class="top_nav_section_right">
                    <span>EMAIL: office@tanweerassociates.com</span>
                </div>
              </div> */}
            {/* </div> */}
          {/* </nav> */}
          {/* <nav class="navbar navbar-expand-lg navbar-light center_nav">
            <div class="container justify-content-center">
              <Link to="/" exact={true} >  {imgCondition()} </Link> 
            </div>
          </nav> */}
        <div class="main_menu">
          <nav class="navbar navbar-expand-lg navbar-light">
            <div class="container">
              <Link to="/" exact={true} >  {imgCondition()} </Link>
              <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
              </button>

              <div class="collapse navbar-collapse offset" id="navbarSupportedContent">
                <ul class="nav navbar-nav menu_nav justify-content-end">
                <li class="nav-item"><Link className="nav-link" activeClassName="active" to="/" exact={true} >Home</Link></li> 
                {/* <li class="nav-item"><Link className="nav-link" to="/our_team" exact={true} >Our Team</Link></li>  */}
                  {/* <li class="nav-item"><Link className="nav-link" to="/about" exact={true} >About Us</Link></li>  */}
                  <li class="nav-item"><Link className="nav-link" to="/our_clients" exact={true} >Our Clients</Link></li> 
                  <li class="nav-item"><Link className="nav-link" to="/our_expertise" exact={true} >Our Expertise</Link></li>
                  {/* <li class="nav-item submenu dropdown">
                    <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                      aria-expanded="false">Pages</a>
                    <ul class="dropdown-menu">
                      <li class="nav-item"><a class="nav-link" href="blog.html">Blog</a></li>
                      <li class="nav-item"><a class="nav-link" href="blog-details.html">Blog Details</a></li>
                    </ul>
                  </li> */}
                  <li class="nav-item"><Link className="nav-link" to="/contact" exact={true} >Contact Us</Link></li>
                </ul>
              </div> 
            </div>
          </nav>
        </div>
      </header>
    </>
  );
}

export default Navbar;
