import React from 'react';
import { Carousel } from 'react-bootstrap';

const CarouselContainer = () => {
  const screenWidth = window.innerWidth

  return (
    <Carousel fade={true} >
      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"
          src="assets/img/images/high-court960X420.png"
          alt="Third slide"
        />
        {/* {(screenWidth > 500)&&(
          <Carousel.Caption>
          <h1 style={{"color":"white"}}>T&A</h1>
              <h5 style={{"color":"white"}} >TANWEER & ASSOCIATES</h5>
            <p>Advocates, Consultants & IP Attorneys</p>
          </Carousel.Caption>
        )} */}
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"
          src="assets/img/images/q1.png"
          alt="First slide"
        />
        {/* {(screenWidth > 500)&&(
          <Carousel.Caption>
          <h1 style={{"color":"white"}}>T&A</h1>
              <h5 style={{"color":"white"}} >TANWEER & ASSOCIATES</h5>
            <p>Advocates, Consultants & IP Attorneys</p>
          </Carousel.Caption>
        )} */}
      </Carousel.Item>
       

     {/* <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"
          src={image3}
          alt="Third slide"
        />
        {(screenWidth > 500)&&(
          <Carousel.Caption>
          <h1 style={{"color":"white"}}>T&A</h1>
              <h5 style={{"color":"white"}} >TANWEER & ASSOCIATES</h5>
            <p>Advocates, Consultants & IP Attorneys</p>
          </Carousel.Caption>
        )}
      </Carousel.Item>

      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"
          src={image4}
          alt="Third slide"
        />
        {(screenWidth > 500)&&(
          <Carousel.Caption>
          <h1 style={{"color":"white"}}>T&A</h1>
              <h5 style={{"color":"white"}} >TANWEER & ASSOCIATES</h5>
            <p>Advocates, Consultants & IP Attorneys</p>
          </Carousel.Caption>
        )}
      </Carousel.Item>

      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"
          src={image5}
          alt="Third slide"
        />
        {(screenWidth > 500)&&(
          <Carousel.Caption>
          <h1 style={{"color":"white"}}>T&A</h1>
              <h5 style={{"color":"white"}} >TANWEER & ASSOCIATES</h5>
            <p>Advocates, Consultants & IP Attorneys</p>
          </Carousel.Caption>
        )}
      </Carousel.Item> */}

    </Carousel>
  )
}

export default CarouselContainer;