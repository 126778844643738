import React from 'react';
import CarouselContainer from "./CarouselContainer";

function Slider() {
    return (
        <>
            {/* <!-- slider Area Start--> */}
            {/* <div class="slider-area ">
                <div class="slider-active">
                        <div class="single-slider slider-height d-flex align-items-center" style={{backgroundImage:"url("+"assets/img/hero/law_T&A.png"+")"}}/>
                </div>
            </div>  */}
            {/* <!-- slider Area End--> */}
            <CarouselContainer />
        </>
    );
  }
  
  export default Slider;