import React from 'react';
import Header from './Shared/Header';

function OurTeam() {
    return (
        <>
           {/* <!-- Team Mates Start --> */}
           <div class="teams-area section-padding30">
            <div class="container">
                <div class="row ">
                    <div class="col-xl-12">
                        <div class="section-tittle section-tittle-f text-center mb-70">
                            <h2>Our Team</h2>
                        </div>
                    </div>
                </div>
                <div class="row" style={{justifyContent:"center"}}>
                    <h4>Under construction</h4>

                    {/* <div class="col-xl-4 col-lg-4 col-md-4">
                        <div class="single-teams text-center">
                            <div class="team-img">
                                <img src="assets/img/team/team_1.jpg" alt=""/>
                            </div>
                            <div class="team-caption">
                                <h4><a href="#">Syed Tanweer Ashraf</a></h4>
                                <span>Managing Partner</span>
                                <p>Advocate Supreme Court</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4">
                        <div class="single-teams text-center">
                            <div class="team-img">
                                <img src="assets/img/team/team_1.jpg" alt=""/>
                            </div>
                            <div class="team-caption">
                                <h4><a href="#">Syed Shaheryar Tanweer</a></h4>
                                <span>Partner</span>
                                <p>Barrister-at-Law | L.L.B. (Hons.) (UK)</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4">
                        <div class="single-teams text-center">
                            <div class="team-img">
                                <img src="assets/img/team/team_3.jpg" alt=""/>
                            </div>
                            <div class="team-caption">
                                <h4><a href="#">Khayam Atta Mangi</a></h4>
                                <span>Legal Associate</span>
                                <p>B.A (L.L.B.)</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4">
                        <div class="single-teams text-center">
                            <div class="team-img">
                                <img src="assets/img/team/team_3.jpg" alt=""/>
                            </div>
                            <div class="team-caption">
                                <h4><a href="#">Tashfeen Taj Khosa</a></h4>
                                <span>Legal Associate</span>
                                <p>L.L.B. (Hons.)</p>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            </div>
        {/* <!-- Team Mates End--> */}
        </>
    );
  }
  
  export default OurTeam;