
import React from 'react';
import { NavLink, Link } from 'react-router-dom';

function Footer() {
    const screenWidth = window.innerWidth

    const imgCondition =() =>{
        if(screenWidth < 400)
            return <img src="assets/logo/logo-orignal250X.png" alt=""/>
        else if(screenWidth < 900)
            return  <img src="assets/logo/logo-orignal300X.png" alt=""/>
        return <img src="assets/logo/T&A-logo500X.png" alt=""/>
    }
    return (
        <>
            {/* <!-- Footer Start--> */}
            <div class="footer-area footer-bg footer-padding">
                <div class="container">
                    <div class="row d-flex justify-content-between">
                        <div class="col-xl-6 col-lg-7 col-md-12 col-sm-12">
                            <div class="single-footer-caption mb-50 text-center">
                                {/* <!-- logo --> */}
                                <div class="footer-logo">
                                    <Link to="/" exact={true} >
                                        {imgCondition()}
                                    </Link>
                                </div>
                                {/* <!-- <div class="single-footer-caption mb-30">
                                    <div class="footer-tittle">
                                        <div class="footer-pera">
                                            <p>Seddo eiusmod tempor incididunt ut labore et dolore magna aliqua. consectetur pisicin elit, sed do eiusmod tempor.</p>
                                    </div>
                                    </div>
                                </div> --> */}
                                {/* <!-- footer social --> */}
                                {/* <div class="footer-social ">
                                    <a href="#"><i class="fab fa-facebook-f"></i></a>
                                    <a href="#"><i class="fab fa-twitter"></i></a>
                                    <a href="#"><i class="fas fa-globe"></i></a>
                                    <a href="#"><i class="fab fa-behance"></i></a>
                                </div> */}
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                            <div class="single-footer-caption mb-50">
                                <div class="footer-tittle">
                                    <h4>Company</h4>
                                    <ul>
                                        <li><Link to="/" exact={true} >Home</Link></li>
                                        {/* <li><Link to="/about" exact={true} >About Us</Link></li> */}
                                        <li><Link to="/our_expertise" exact={true} >Our Expertise</Link></li>
                                        <li><Link to="/our_clients" exact={true} >Our Clients</Link></li>
                                        <li><Link to="/contact" exact={true} >Contact Us</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-2 col-md-6 col-sm-6">
                            <div class="single-footer-caption mb-50">
                                <div class="footer-tittle">
                                    <h4>Expertise</h4>
                                    <ul>
                                        <li><a href="#">Company Laws</a></li>
                                        <li><a href="#">Environmental Laws</a></li>
                                        <li><a href="#">Drug Laws</a></li>
                                        <li><a href="#">Insurance Laws</a></li>
                                        <li><a href="#">Partnership Laws</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        </div>
                </div>
            </div>
            {/* <!-- footer-bottom aera --> */}
            <div class="footer-bottom-area footer-bg">
                <div class="container">
                    <div class="footer-border">
                            <div class="row d-flex align-items-center">
                                <div class="col-xl-12">
                                    <div class="footer-copy-right text-center">
                                        <p>Copyright &copy;2022 All rights reserved</p>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            {/* <!-- Footer End--> */}
        </>
    );
  }
  
  export default Footer;