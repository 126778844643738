import React from 'react';
function Header(props) {
    const {name} = props;
    return (
        <>
           {/* <!-- slider Area Start--> */}
           <div class="slider-area ">
            {/* <!-- Mobile Menu --> */}
            <div class="single-slider slider-height2 d-flex align-items-center" style={{backgroundImage:"url("+"assets/img/hero/law_hero2.jpg"+")"}} >
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="hero-cap text-center">
                                <h2>{name}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- slider Area End--> */}
        </>
    );
  }
  
  export default Header;