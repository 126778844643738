
import {useEffect} from 'react';
import Header from './Slider';
import { NavLink, Link } from 'react-router-dom';

function Home() {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    
     
    return (
    <>
        <Header/>

        {/* <!-- Recent-case-area Start --> */}
         {/* <section class="recent-case-area section-padding30">
            <div class="container">
                <div class="row ">
                    <div class="col-xl-12">
                        <div class="section-tittle section-tittle-f text-center mb-70">
                            <h2>Our Clients</h2>
                        </div>
                    </div>
                </div>
                 <div class="tab-content" id="nav-tabContent">
                    <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-img">
                                        <img src="assets/clients/amnah.png" alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-img">
                                        <img src="assets/clients/efu general.png" alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-img">
                                        <img src="assets/clients/jaffer group.png" alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-img">
                                        <img src="assets/clients/linz.png" alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-img">
                                        <img src="assets/clients/m&p.png" alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-img">
                                        <img src="assets/clients/pel.png" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <Link className="genric-btn link" to="/our_clients" exact={true} >Read More</Link>
                </div>
            </div>
        </section> */}
        {/* <!-- Recent-case-area End --> */}

        {/* <!-- About Law Start--> */}
        {/* <!-- About Law Start--> */}
        <div class="about-low-area about-bg about-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-5 col-lg-5">
                            {/* <!-- section tittle --> */}
                            <div class="section-tittle section-tittle-f mb-70">
                                <h2>About Us</h2>
                            </div>
                            <div class="about-caption mb-100 text-justify">
                                <p>We are a law firm registered with the Sindh Bar Council and the Registrar of Firms having offices at Karachi, Quetta and Gwadar. We provide legal and consultancy services to our clients all over Pakistan as well as abroad.</p>
                                <p>We have a diverse team of Barristers, Advocates and Associates who deal in cases at different forums, including inter alia, The Supreme Court of Pakistan, High Courts, District Courts, Office of the Ombudsman, Drug Courts, Trademarks and Copyright Registry, Board of Revenue and various tribunals including the Intellectual Property Tribunal, Customs Tribunal, Insurance Tribunal etc.</p>
                            </div>
                        </div>
                        <div class="col-xl-7 col-lg-7">
                            {/* <!-- about-img --> */}
                            <div class="about-img ">
                                <div class="about-font-img f-left">
                                    <img src="assets/img/images/law_firm600X.png" alt=""/>
                                 </div>
                                {/* <div class="about-back-img f-right d-none d-md-block">
                                    <img src="assets/img/images/law_firm774X500.png" alt=""/>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-12 col-lg-12">
                        {/* <!-- section tittle --> */}
                        {/* <div class="section-tittle section-tittle-l mb-30">
                            <h3>OUR VISION</h3>
                        </div> */}
                        <div class="section-tittle section-tittle-f mb-70">
                            <h2>Our Vision</h2>
                        </div>
                        <div class="about-caption mb-10 text-justify">
                            <p>We aspire to achieve objectives while adhering to the core values of honour, integrity, impartiality, objectivity, honesty and credibility. We aim to uphold the rule of law while striving hard to defend the honour of our profession by adopting the highest degree of professionalism, craftsmanship and the preservation of our much valued client secrets.</p>
                            <p>We try to provide high-end solutions and deal with all sort of legal issues by maximum utilization of our knowledge, experience and skills so that we can solve the most critical problems in minimum possible time with peaceful ways.</p>
                            </div>
                    </div>
                </div>
            </div>
        {/* <!-- About Law End--> */}

        {/* <!-- Legal Practice Area start --> */}
          {/* <div class="legal-practice-area section-padding30">
            <div class="container">
                <div class="row ">
                    <div class="col-xl-12">
                        <div class="section-tittle text-center mb-70">
                            <h2>Areas Of Expertise.</h2>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-4 col-lg-4 col-md-6">
                        <div class="single-practice mb-30">
                            <div class="practice-caption">
                                <h4><a href="">Admiralty & Shipping</a></h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6">
                        <div class="single-practice mb-30">
                            <div class="practice-caption">
                                <h4><a href="">Arbitration & Dispute Resolution</a></h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6">
                        <div class="single-practice mb-30">
                            <div class="practice-caption">
                                <h4><a href="">Banking Laws</a></h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6">
                        <div class="single-practice mb-30">
                            <div class="practice-caption">
                                <h4><a href="">Company Law</a></h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6">
                        <div class="single-practice mb-30">
                            <div class="practice-caption">
                                <h4><a href="">Constitutional Law</a></h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6">
                        <div class="single-practice mb-30">
                            <div class="practice-caption">
                                <h4><a href="">Co-operative Housing Society Laws</a></h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <Link className="genric-btn link" to="/our_expertise" exact={true} >Read More</Link>
                </div>
            </div>
        </div> */}
        {/* <!-- Legal Practice Area End--> */}

        {/* <!-- ================ contact section start ================= --> */}
        <section class="contact-section">
            <div class="container">
                <div class="row ">
                    <div class="col-xl-12">
                        <div class="section-tittle text-center mb-70">
                            <h2>Offices</h2>
                        </div>
                    </div>
                </div>
                <div class="row mb-5">
                    <div class="col-12">
                        <h1 class="contact-title">Head Office</h1>
                    </div>
                    <div class="col-lg-8">
                        <div class="d-sm-block ">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2416.2831852288473!2d67.03413966529074!3d24.852434709520363!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33f4d96879c2b%3A0xa722f315a4947419!2sTANWEER%20%26%20ASSOCIATES!5e0!3m2!1sen!2s!4v1639412668620!5m2!1sen!2s" width="100%" height="350" style={{border:"0"}} allowfullscreen="" loading="lazy"/>
                        </div>
                    </div>
                    <div class="col-lg-4 pt-55">
                        <div class="media contact-info">
                            <span class="contact-info__icon"><i class="ti-home"></i></span>
                            <div class="media-body">
                                <h3>Shahra-e-Faisal, Karachi.</h3>
                                <p>307, Kashif Centre, Near Mehran Hotel.</p>
                            </div>
                        </div>
                        <div class="media contact-info">
                            <span class="contact-info__icon"><i class="ti-tablet"></i></span>
                            <div class="media-body">
                                <h3>+921-35640302-04</h3>
                                <p>Mon to Sat 10am to 6pm</p>
                            </div>
                        </div>
                        <div class="media contact-info">
                            <span class="contact-info__icon"><i class="ti-email"></i></span>
                            <div class="media-body">
                                <h3>office@tanweerassociates.com</h3>
                                <p>Send us your query anytime!</p>
                            </div>
                        </div>
                    </div>
                
                </div>
                <div class="row mb-5">
                    <div class="col-lg-3 pt-55">
                    </div>
                    <div class="col-lg-4 pt-55">
                        {/* <div class="col-12"> */}
                            <h1 class="contact-title">Quetta Office</h1>
                        {/* </div> */}
                        <div class="media contact-info">
                            <span class="contact-info__icon"><i class="ti-home"></i></span>
                            <div class="media-body">
                                <h3>Shahra-e-Liaqat, Quetta</h3>
                                <p>26, Baldia Plaza, 3rd Floor</p>
                            </div>
                        </div>
                        <div class="media contact-info">
                            <span class="contact-info__icon"><i class="ti-tablet"></i></span>
                            <div class="media-body">
                                <h3>+921-35640302-04</h3>
                                <p>Mon to Sat 10am to 6pm</p>
                            </div>
                        </div>
                        <div class="media contact-info">
                            <span class="contact-info__icon"><i class="ti-email"></i></span>
                            <div class="media-body">
                                <h3>office@tanweerassociates.com</h3>
                                <p>Send us your query anytime!</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 pt-55">
                        {/* <div class="col-12"> */}
                            <h1 class="contact-title">Gwadar Office</h1>
                        {/* </div> */}
                        <div class="media contact-info">
                            <span class="contact-info__icon"><i class="ti-home"></i></span>
                            <div class="media-body">
                                <h3>Airport Road, Gwadar.</h3>
                                <p>Opposite PIA Office.</p>
                            </div>
                        </div>
                        <div class="media contact-info">
                            <span class="contact-info__icon"><i class="ti-tablet"></i></span>
                            <div class="media-body">
                                <h3>+921-35640302-04</h3>
                                <p>Mon to Sat 10am to 6pm</p>
                            </div>
                        </div>
                        <div class="media contact-info">
                            <span class="contact-info__icon"><i class="ti-email"></i></span>
                            <div class="media-body">
                                <h3>office@tanweerassociates.com</h3>
                                <p>Send us your query anytime!</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-1 pt-55">
                    </div>
                </div>
                {/*   <div class="row mb-5">
                    <div class="col-12">
                        <h1 class="contact-title">Gwadar Office</h1>
                    </div>
                    <div class="col-lg-8">
                        <div class="d-sm-block ">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2416.2831852288473!2d67.03413966529074!3d24.852434709520363!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33f4d96879c2b%3A0xa722f315a4947419!2sTANWEER%20%26%20ASSOCIATES!5e0!3m2!1sen!2s!4v1639412668620!5m2!1sen!2s" width="100%" height="350" style={{border:"0"}} allowfullscreen="" loading="lazy"/>
                        </div>
                    </div>
                    <div class="col-lg-4 pt-55">
                        <div class="media contact-info">
                            <span class="contact-info__icon"><i class="ti-home"></i></span>
                            <div class="media-body">
                                <h3>Airport Road, Gwadar.</h3>
                                <p>Opposite PIA Office.</p>
                            </div>
                        </div>
                        <div class="media contact-info">
                            <span class="contact-info__icon"><i class="ti-tablet"></i></span>
                            <div class="media-body">
                                <h3>+921-35640302-04</h3>
                                <p>Mon to Sat 10am to 6pm</p>
                            </div>
                        </div>
                        <div class="media contact-info">
                            <span class="contact-info__icon"><i class="ti-email"></i></span>
                            <div class="media-body">
                                <h3>office@tanweerassociates.com</h3>
                                <p>Send us your query anytime!</p>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </section>
        {/* <!-- ================ contact section end ================= --> */}
    </>
    );
}

export default Home;
