import {useEffect} from 'react';
import Header from '../Shared/Header';
import "./style.css";

function OurClients() {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <>
        <Header name={"Our Clients"}/>
        {/* <!-- Recent-case-area Start --> */}
        <section class="recent-case-area section-padding30">
            <div class="container">
                {/* <!--Section Tittle  --> */}
                <div class="row ">
                    <div class="col-xl-12">
                        <div class="section-tittle section-tittle-l mb-30">
                            <h1>Our Major Clients Include:</h1>
                        </div>
                        <div class="section-tittle section-tittle-l mb-10">
                            <h2>Government entities</h2>
                        </div>
                        
                    </div>
                </div>
                <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Government of Balochistan (Balochistan Development Authority)</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Port Qasim Authority</a></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="col-xl-12">
                                <div class="section-tittle section-tittle-l mb-30 mt-50">
                                    <h2>Corporate Clients</h2>
                                </div>
                                
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Muller & Phipps (M&P)</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Pakistan Elektron Limited (PEL)</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >EFU</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Jaffer Group</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Multi Food Industries</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >M. Elyas & Sons</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Habib Education Trust</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Modern Plastic Industries</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Dynea Pakistan</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >1Celera International</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >1Amanah Investments Limited</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >1A. Essak & Sons</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >1Customs Cricket Academy</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >1PMI Karachi Chapter</a></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="col-xl-12">
                                <div class="section-tittle section-tittle-l mb-30 mt-50">
                                    <h2>Ship Breakering Industry</h2>
                                </div>
                                
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Pakistan Ship Breakers Association</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Fatima Enterprises</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Venture Green Recyclers</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Salam’s International</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >New Choice Enterprises</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Usman Ship Breakers</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >A.F Steel Re-Rolling Mills</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >A.A. Shipbreakers</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Commercial Metal</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >1Sadaf Enterprises </a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >1Decent Trading Company</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >1Ghaffar & Co.</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >1Dewan & Sons</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >1Jamaluddin & Co.</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >1Unique Trading Company</a></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row ">
                    <div class="col-xl-12">
                        <div class="section-tittle section-tittle-l mb-30 mt-50">
                            <h2>Builders </h2>
                        </div>
                        
                    </div>
                </div>
                <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Taloo Group</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Osman Housing Limited</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Shamsi Builders</a></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row ">
                    <div class="col-xl-12">
                        <div class="section-tittle section-tittle-l mb-30 mt-50">
                            <h2>Pharmaceutical Companies</h2>
                        </div>
                        
                    </div>
                </div>
                <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Bosch Pharmaceuticals (Pvt.) Ltd</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Linz Pharmaceuticals</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Martin Dow Group</a></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row ">
                    <div class="col-xl-12">
                        <div class="section-tittle section-tittle-l mb-30 mt-50">
                            <h2>FMCG Companies</h2>
                        </div>
                        
                    </div>
                </div>
                <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Euro Cool Chain (Pvt.) Ltd</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Mahnoor Food Industries</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >United International Industries</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Euro Food Industries</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >Modern Plastic Industries</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6">
                                <div class="single-practice mb-30">
                                    <div class="practice-caption">
                                        <h5><a >M/s. Tradelink</a></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
        </section>
        {/* <!-- Recent-case-area End --> */}
        </>
    );
  }
  
  export default OurClients;